<!--PublishPostBtn:发布帖子按钮 2024/07/28 11:24:15 huangmx-->

<template>
  <div class="publish-post-btn" :class="{ 'fixed': !visible }">
    <el-button type="primary" round icon="el-icon-edit" @click="showWindow">{{ $t('btnPost') }}</el-button>

    <publish-post-dialog :visible="visible" @close="visible = false"></publish-post-dialog>
  </div>
</template>
<script>
import PublishPostDialog from './PublishPostDialog.vue'
export default {
  name: 'PublishPostBtn',
  components: {
    PublishPostDialog
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      visible: false
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    checkLogin: function () {
      if (!this.$store.state.user || this.$store.state.user.name === '未登录') {
        this.$store.state.loginVisible = true
        return false
      }
      return true
    },
    showWindow: function () {
      if (!this.checkLogin()) {
        return
      }
      this.visible = true
    }
  }
}
</script>

<style scoped>
.publish-post-btn {
  right: 40%;
  bottom: 110px;
  text-align: center;
}

.fixed {
  position: fixed;
}

.publish-post-btn>>>.el-button {
  font-size: 16px;
}
</style>
